import { lazy, StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BlockingDialog } from "./components/BlockingDialog";

import "context-filter-polyfill";
import { NotificationBubble } from "./components/NotificationBubble";

import { setupMocks } from "./utils/mocks";
import { DownloadPrompt } from "./components/DownloadPrompt";
import { setupAnalytics } from "./utils/analytics";
import * as Sentry from "@sentry/react";

import { Capacitor } from '@capacitor/core';

import packageJson from '../package.json';
import { messageDiscord } from "./utils/discord";


const ABView = lazy(async () => ({ default: (await import("./views/ABView")).ABView }));
const LandingView = lazy(async () => ({ default: (await import("./views/LandingView")).LandingView }));
const AppView = lazy(async () => ({ default: (await import("./views/AppView")).AppView }));
const NativeAppView = lazy(async () => ({ default: (await import("./views/NativeAppView")).NativeAppView }));

try {
  console.info('📸 Reflight Studio', packageJson.version);

  // const isAppView = window.location.host.startsWith("app.");
  const isNativeApp = Capacitor.getPlatform() !== 'web';
  const isABView = !isNativeApp && window.location.host.startsWith("ab.");
  const isAppView = true;

  if (!isABView) {
    Sentry.init({
      dsn: "https://8bbf7c34039b4dfff7616a11ce7e6c46@o4507012181327872.ingest.us.sentry.io/4508336499195904",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      sampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: window.location.href.includes('localhost') ? 'dev' : 'prod',
    });
  }

  setupMocks();

  if (isABView) {
    createRoot(document.getElementById("root")!).render(
      <StrictMode>
        <Suspense fallback={null}>
          <ABView />
        </Suspense>
        <NotificationBubble />
        <BlockingDialog />
        <DownloadPrompt />
      </StrictMode>
    );
  } else {
    setupAnalytics();

    createRoot(document.getElementById("root")!).render(
      <StrictMode>
        <Suspense fallback={null}>
          {isAppView ? (
            isNativeApp ? <NativeAppView /> : <AppView />
          ) : <LandingView />}
        </Suspense>
        <NotificationBubble />
        <BlockingDialog />
        <DownloadPrompt />
      </StrictMode>
    );

    setTimeout(() => {
      messageDiscord('👀 Stayed in app more than 30seconds');
    }, 30 * 1000);

    setTimeout(() => {
      messageDiscord('🤔 Stayed in app more than 1min');
    }, 60 * 1000);

    setTimeout(() => {
      messageDiscord('😳 Stayed in app more than 5min');
    }, 5 * 60 * 1000);

    setTimeout(() => {
      const onceInteraction = (event: PointerEvent) => {
        document.removeEventListener('pointerdown', onceInteraction);
    
        messageDiscord(`👆 Clicked (${(event.target as any)?.className ?? 'empty'})`);
      };
    
      document.addEventListener('pointerdown', onceInteraction);
    }, 3000);
  }
} catch (error: any) {
  messageDiscord(`💔 Global error: ${error?.message || error}`);
}