import styled from "styled-components";
import { zIndexOrder } from "../utils/layers";
import { useEditorState } from "../state/editorState";
import { useEffect, useMemo } from "react";
import { Plane } from "../types/editorState";
import { Icon } from "./Icon";
import { translation } from "../translations";

export const DownloadPrompt = () => {
  const { downloadPrompt, showDownloadPrompt, workingArea } = useEditorState();

  const size = useMemo<Plane>(() => {
    if (!workingArea) {
      return {
        width: 1.0,
        height: 1.0,
      };
    }

    if (workingArea.width > 1.0) {
      return {
        width: 1.0,
        height: workingArea.height / workingArea.width,
      };
    } else {
      return {
        width: workingArea.width,
        height: workingArea.height,
      };
    }
  }, [workingArea]);

  const onBackdropClick = () => {
    if (!downloadPrompt) {
      return;
    }

    showDownloadPrompt(null);
  };

  useEffect(() => {
    if (!downloadPrompt) {
      return;
    }

    return () => {
      try {
        URL.revokeObjectURL(downloadPrompt);
      } catch {
        // NOTE Ignore
      }
    }
  }, [downloadPrompt]);

  return (
    <Backdrop
      $active={!!downloadPrompt}
      onClick={onBackdropClick}  
    >
      {downloadPrompt && (
        <Container
          $size={size}
          $background={downloadPrompt}
        >
          <PreviewImage
            tabIndex={1}
            src={downloadPrompt}
            alt="Download"
            $size={size}
            onPointerDown={event => {
              event.stopPropagation();
            }}
            onClick={event => {
              event.stopPropagation();
            }}
          />
          <AnimatedIcon>
            <Icon
              style={{
                pointerEvents: 'none'
              }}
              name="hold"
              color="#ffffff"
              size={128}
            />
          </AnimatedIcon>
          <AnimatedTap />
          <IconLabel>
            {translation.download1}<br/>
            {translation.download2}{' '}<Highlight>{translation.download3}</Highlight>
          </IconLabel>
        </Container>
      )}
    </Backdrop>
  );
};

const PreviewImage = styled.img<{
  $size: Plane;
}>`
  width: ${({ $size }) => $size.width * 97}vmin;
  height: ${({ $size }) => $size.height * 97}vmin;
  aspect-ratio: 1 / 1;
  opacity: 0.0;
  border-radius: 4px;
  pointer-events: all !important;
`;

const Highlight = styled.span`
  color: rgba(104,255,104,1.0);
  pointer-events: none;
`;

const IconLabel = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  font-size: 12px;
  color: #cccccc;
  background-color: #000000cc;
  padding: 8px 12px;
  border-radius: 4px 4px 0 0;
  text-align: left;
  z-index: 10;
  pointer-events: none;
`;

const AnimatedTap = styled.div`
  @keyframes AnimatedTapIndicator {
    0% {
      opacity: 0.0;
      transform: scale(0.5);
    }

    35% {
      opacity: 0.0;
      transform: scale(0.5);
    }

    50% {
      opacity: 0.75;
      transform: scale(1.0);
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }

    60% {
      opacity: 0.0;
      transform: scale(1.5);
      filter: blur(8px);
      -webkit-filter: blur(8px);
    }

    100% {
      opacity: 0.0;
      transform: scale(0.5);
    }
  }

  position: absolute;
  bottom: 74px;
  right: 92px;
  width: 64px;
  height: 64px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 0.0;
  z-index: 2;
  animation: AnimatedTapIndicator 2.0s ease both infinite;
  pointer-events: none;
`;

const AnimatedIcon = styled.div`
  @keyframes HoldIconIndicator {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }

    30% {
      transform: translate(-12px, 4px) rotate(-10deg);
    }

    80% {
      transform: translate(-12px, 4px) rotate(-10deg);
    }

    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }

  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 3;
  animation: HoldIconIndicator 2.0s ease both infinite;
  pointer-events: none;
`;

const Container = styled.div<{
  $size: Plane;
  $background: string;
}>`
  @keyframes BlockingDialogPopUp {
    0% {
      transform: translate(-50%, -45%) scale(1.0);
    }

    60% {
      transform: translate(-50%, -55%) scale(1.1);
    }

    100% {
      transform: translate(-50%, -50%) scale(1.0);
    }
  }

  position: fixed;
  top: 50%;
  left: 50%;
  width: ${({ $size }) => $size.width * 97}vmin;
  height: ${({ $size }) => $size.height * 97}vmin;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 8px;
  background: #111111;
  background-image: url('${({ $background }) => $background}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: BlockingDialogPopUp 0.3s ease-in-out alternate 1;
  opacity: 0.9;
  pointer-events: none;
  z-index: 2;
`;

const Backdrop = styled.div<{
  $active: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000088;
  z-index: ${zIndexOrder.downloadPromptZIndex};
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: opacity 0.2s;

  ${({ $active }) => $active ? `
    pointer-events: all;
    opacity: 1.0;
  ` : `
    pointer-events: none;
    opacity: 0.0;
  `}

  & * {
    pointer-events: none;
  }
`;