export const zIndexOrder = {
  baseZIndex: 1,
  tileGridZIndex: 9,
  imagePickerZIndex: 10,
  dropzoneTargetZIndex: 75,
  lightControllersZIndex: 75,
  toolbarsZIndex: 80,
  notificationBubbleZIndex: 100,
  blockingDialogZIndex: 125,
  downloadPromptZIndex: 126,
};
