import * as THREE from "three";
import * as Sentry from "@sentry/react";

export const storeValue = async (value: any, presetUuid?: string) => {
  const uuid = presetUuid || THREE.MathUtils.generateUUID();

  if (presetUuid === 'analytics') {
    try {
      await fetch('https://gpu-host-th-1.wtlstudio-offsite.com/ab', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: 'app',
          info: JSON.stringify(value.values)
        })
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  try {
    localStorage.setItem(uuid, JSON.stringify(value));
  } catch (error) {
    Sentry.captureException(error);
  }

  return uuid;
};

export const readValue = async <T>(uuid: string): Promise<T | null> => {
  try {
    return localStorage.getItem(uuid) ? JSON.parse(sessionStorage.getItem(uuid)!) : null;
  } catch (error) {
    Sentry.captureException(error);

    return null;
  }
};

export const flushStorage = async () => {
  try {
    localStorage.clear();
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const downloadBase64AsImage = (base64: string, filename: string) => {
  const a = document.createElement("a");
  a.href = base64;
  a.download = filename;

  a.click();
};

export const pickObjectKeys = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> => {
  const result: any = {};

  keys.forEach((key) => {
    result[key] = obj[key];
  });

  return result;
};

export const isTouchDevice = () => {
  return (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);
};
