import * as THREE from "three";
import { Box } from "../types/editorState";

const canvas = document.createElement("canvas");
const ctx = canvas.getContext("2d");
const imageLoader = document.createElement("img");

const tempVector = new THREE.Vector2();
const zeroVector = new THREE.Vector2();

export const applyWorkareaTransform = async (
  image: string,
  width: number,
  height: number,
  cropBox: Box | null,
  rotation: number
) => {
  const transformedBase64 = new Promise<string>((resolve) => {
    imageLoader.onload = () => {
      if (!ctx) {
        return;
      }

      ctx.save();

      if (cropBox) {
        const cropWidth = (cropBox.x2 - cropBox.x1) * width;
        const cropHeight = (cropBox.y2 - cropBox.y1) * height;

        tempVector.set(cropWidth, cropHeight);
      } else {
        tempVector.set(width, height);
      }

      canvas.width = tempVector.x;
      canvas.height = tempVector.y;

      if (rotation) {
        tempVector.rotateAround(zeroVector, rotation).round();
        tempVector.x = Math.abs(tempVector.x);
        tempVector.y = Math.abs(tempVector.y);

        canvas.width = tempVector.x;
        canvas.height = tempVector.y;

        ctx.translate(tempVector.x / 2, tempVector.y / 2);
        ctx.rotate(-rotation);

        tempVector.rotateAround(zeroVector, -rotation).round();
        tempVector.x = Math.abs(tempVector.x);
        tempVector.y = Math.abs(tempVector.y);
        ctx.translate(-tempVector.x / 2, -tempVector.y / 2);
      }

      if (cropBox) {
        ctx.translate(
          -cropBox.x1 * width,
          -cropBox.y1 * height,
        );
      }

      ctx.drawImage(imageLoader, 0, 0);

      resolve(ctx.canvas.toDataURL("image/jpeg"));

      ctx.restore();
    };
    imageLoader.src = image;
  });

  return transformedBase64;
};

export const getFittingImageAspectSize = (
  width: number,
  height: number,
  maxSize: number = 2048
) => {
  if (width <= maxSize && height <= maxSize) {
    return {
      width,
      height,
    };
  }

  if (width >= height) {
    return {
      width: maxSize,
      height: Math.round((maxSize / width) * height),
    };
  } else {
    return {
      width: Math.round((maxSize / height) * width),
      height: maxSize,
    };
  }
};

export const getBlob = async (canvas: HTMLCanvasElement) => {
  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob(resolve, "image/jpeg", 1.0);
  });
};

export const getBase64String = async (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(blob);
  });
};
