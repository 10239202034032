import { create } from "zustand";
import { Box, Coords, FileContract, HistoryStep, Plane, Tool } from "../types/editorState";
import { Command } from "../types/commands";
import { getBase64String } from "../utils/transforms";
import { pickObjectKeys } from "../utils/storage";
import { Layer, Light } from "../types/relight";
import { Config } from "../config";
import { recordExport } from "../utils/analytics";
import { Highlight } from "../utils/texture-highlights";

export type EditorState = {
  editorVersion: number;
  file: FileContract | null;
  tool: Tool;
  workingArea: Plane | null;
  workingAreaRotation: number;
  workingAreaDifferentialPreviewRatio: number;
  cropBox: Box | null;
  viewportCenter: Coords;
  historyBack: HistoryStep[];
  historyForth: HistoryStep[];
  notification: string | null;
  leftBar: 'modifiers' | 'layers';
  lights: Light[];
  background: Layer;
  foreground: Layer;
  exportQueued: boolean;
  downloadPrompt: string | null;
  highlights: Highlight[];

  execute: (command: Command<any>, payload: any) => void;
  setFile: (file: FileContract | null) => void;
  setTool: (tool: Tool) => void;
  setWorkingArea: (workingArea: Plane | null) => void;
  setWorkingAreaRotation: (rotation: number) => void;
  setWorkingAreaDifferentialPreviewRatio: (ratio: number) => void;
  setCropBox: (box: Box | null) => void;
  setViewportCenter: (center: Coords) => void;
  showNotification: (message: string | null) => void;
  setLeftBar: (leftBar: 'modifiers' | 'layers') => void;
  setLights: (lights: Light[]) => void;
  setBackground: (background: Layer) => void;
  setForeground: (foreground: Layer) => void;
  setHighlights: (highlights: Highlight[]) => void;

  undo: () => void;
  redo: () => void;
  setHistory(historyBack?: HistoryStep[], historyForth?: HistoryStep[]): void;

  getRecentImages: () => Promise<string[]>;
  save: () => void;
  export: () => void;
  showDownloadPrompt: (base64: string | null) => void;
};

export const useEditorState = create<EditorState>((set, get) => ({
  editorVersion: Config.editorVersion,
  file: null,
  tool: "move",
  workingArea: null,
  workingAreaRotation: 0.0,
  workingAreaDifferentialPreviewRatio: 1.0,
  cropBox: null,
  viewportCenter: { x: 0.0, y: 0.0 },
  historyBack: [],
  historyForth: [],
  notification: null,
  leftBar: 'modifiers',
  lights: [],
  background: {
    sharpness: 1.0,
    brightness: 0.4,
    saturation: 0.5,
    contrast: 0.6,
    bokeh: 0.0,
    vignette: 0.0,
    offset: 0.5,
    offsetStride: 0.5,
  },
  foreground: {
    sharpness: 1.0,
    brightness: 0.6,
    saturation: 0.5,
    contrast: 0.6,
    bokeh: 0.0,
    vignette: 0.0,
  },
  exportQueued: false,
  downloadPrompt: null,
  highlights: [],

  execute: (command, payload) => {
    const state = get();

    state.historyForth.length = 0;

    command.do(payload);
  },
  setFile: (file) => {
    const { file: currentFile } = get();

    if (currentFile) {
      if (currentFile.base64 && file?.base64 !== currentFile.base64) {
        URL.revokeObjectURL(currentFile.base64);
      }

      if (currentFile.depthBase64 && file?.depthBase64 !== currentFile.depthBase64) {
        URL.revokeObjectURL(currentFile.depthBase64);
      }
    }

    set({ file });
  },
  setTool: (tool) => set({ tool }),
  setWorkingArea: (workingArea) => set({ workingArea }),
  setWorkingAreaRotation: (workingAreaRotation) => set({ workingAreaRotation }),
  setWorkingAreaDifferentialPreviewRatio: (workingAreaDifferentialPreviewRatio) => set({ workingAreaDifferentialPreviewRatio }),
  setCropBox: (cropBox) => set({ cropBox }),
  setViewportCenter: (viewportCenter) => set({ viewportCenter }),
  showNotification: (notification) => set({ notification }),
  setLeftBar: (leftBar) => set({ leftBar }),
  setLights: (lights) => set({ lights }),
  setBackground: (background) => set({ background }),
  setForeground: (foreground) => set({ foreground }),
  setHighlights: (highlights) => set({ highlights }),

  undo: () => {
    const state = get();
    const historyStep = state.historyBack.pop();

    if (!historyStep) {
      return;
    }

    const [command, payload, viewportCenter] = historyStep;

    command.undo(payload);
    state.setViewportCenter(viewportCenter);
    state.showNotification(`Undo ${command.name}`);
  },
  redo: () => {
    const state = get();
    const historyStep = state.historyForth.pop();

    if (!historyStep) {
      return;
    }

    const [command, payload, viewportCenter] = historyStep;

    command.do(payload);
    state.setViewportCenter(viewportCenter);
    state.showNotification(`Redo ${command.name}`);
  },
  setHistory: (historyBack, historyForth) => {
    const update: Partial<EditorState> = {};

    if (historyBack) {
      update.historyBack = historyBack;
    }

    if (historyForth) {
      update.historyForth = historyForth;
    }

    set(update);
  },

  getRecentImages: async (): Promise<string[]> => {
    const imagesList: string[] = [];

    // TODO This can be removed entirely

    return imagesList;
  },
  save: async () => {
    const state = get();

    if (!state.file) {
      return;
    }

    const savedState = pickObjectKeys({ ...state }, [
      'file',
      'editorVersion',
      'workingArea',
      'workingAreaRotation',
      'cropBox',
      'viewportCenter',
      'lights',
      'background',
      'foreground',
    ]);

    if (!savedState.file) {
      return;
    }

    savedState.file.base64 = await getBase64String(
      await fetch(state.file.base64).then(async response => await response.blob())
    );
    savedState.file.depthBase64 = await getBase64String(
      await fetch(state.file.depthBase64).then(async response => await response.blob())
    );

    recordExport();

    // await storeValue(savedState, savedState.file.path);
  },
  export: () => {
    set({ exportQueued: true });
  },
  showDownloadPrompt: (downloadPrompt) => set({ downloadPrompt }),
}));
