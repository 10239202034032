import styled from "styled-components";
import { zIndexOrder } from "../utils/layers";
import { useDialogState } from "../state/dialogState";

export const BlockingDialog = () => {
  const dialogState = useDialogState();

  const onBackdropClick = () => {
    if (!dialogState.activeDialog) {
      return;
    }

    dialogState.activeDialog.onCancel();
    dialogState.setActiveDialog(null);
  };

  return (
    <Backdrop
      onClick={() => {
        onBackdropClick();
      }}
      $active={!!dialogState.activeDialog}
    >
      {dialogState.activeDialog && (
        <Container>
          <Message>
            {dialogState.activeDialog.message}
          </Message>
          <Buttons>
            <Button
              onClick={() => dialogState.activeDialog!.onConfirm()}
            >
              Ok
            </Button>
            <Button
              onClick={() => dialogState.activeDialog!.onCancel()}
            >
              Cancel
            </Button>
          </Buttons>
        </Container>
      )}
    </Backdrop>
  );
};

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  flex: 1;
  text-align: center;
  line-height: 1.23rem;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;
  gap: 1px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  flex: 1;
  width: 100%;
  height: 32px;
  background: #222222;
  transition: background 0.2s;

  &:hover {
    background: #333333;
  }
`;

const Container = styled.div`
  @keyframes BlockingDialogPopUp {
    0% {
      transform: translate(-50%, -45%) scale(1.0);
    }

    60% {
      transform: translate(-50%, -55%) scale(1.1);
    }

    100% {
      transform: translate(-50%, -50%) scale(1.0);
    }
  }

  position: fixed;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 150px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 8px;
  background: #111111;
  animation: BlockingDialogPopUp 0.3s ease-in-out alternate 1;
  overflow: hidden;
  opacity: 0.9;
`;

const Backdrop = styled.div<{
  $active: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000088;
  z-index: ${zIndexOrder.blockingDialogZIndex};
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: opacity 0.2s;

  ${({ $active }) => $active ? `
    pointer-events: all;
    opacity: 1.0;
  ` : `
    pointer-events: none;
    opacity: 0.0;
  `}
`;