import { create } from "zustand";

type DialogConfig = {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

type DialogState = {
  activeDialog: DialogConfig | null;

  setActiveDialog: (dialog: DialogConfig | null) => void;
}

export const useDialogState = create<DialogState>((set) => ({
  activeDialog: null,

  setActiveDialog: (dialog) => set({ activeDialog: dialog }),
}));
