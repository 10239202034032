import styled from "styled-components";
import { zIndexOrder } from "../utils/layers";
import { Icon } from "./Icon";
import { useEditorState } from "../state/editorState";
import { useEffect } from "react";

export const NotificationBubble = () => {
  const editorState = useEditorState();

  useEffect(() => {
    const timeout = setTimeout(() => {
      editorState.showNotification(null);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [editorState.notification]);

  if (!editorState.notification) {
    return null;
  }

  return (
    <Container>
      <NotificationPopUp key={editorState.notification}>
        <Icon name="checkmark" size={64} color="#cccccc" />
      </NotificationPopUp>
      <Label>
        {editorState.notification}
      </Label>
    </Container>
  );
};

const NotificationPopUp = styled.div`
  @keyframes NotificationPopUp {
    0% {
      transform: rotate(0deg) scale(1.0);
    }

    50% {
      transform: rotate(15deg) scale(1.2);
    }

    60% {
      transform: rotate(20deg) scale(1.25);
    }

    80% {
      transform: rotate(-5deg) scale(0.9);
    }

    90% {
      transform: rotate(-10deg) scale(0.85);
    }

    100% {
      transform: rotate(0deg) scale(1.0);
    }
  }

  animation: NotificationPopUp 0.3s ease-in-out alternate 1;
`;

const Label = styled.div`
  font-size: 12px;
`;

const Container = styled.div`
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  gap: 8px;
  background: #11111188;
  color: #cccccc;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  pointer-events: none;
  z-index: ${zIndexOrder.notificationBubbleZIndex};
`;