
const translationEnglish = {
  openPicture: 'Open Picture',
  preparingPicture: 'Preparing Picture',
  cancelOption: 'Cancel?',
  cancellingPicture: 'Cancelling',

  toolRevert: 'Revert All',
  toolRotate: 'Rotate',
  toolCrop: 'Crop',
  toolAddLight: 'Add Light',
  toolBackground: 'Background',
  toolForeground: 'Foreground',
  toolPreview: 'Preview',

  toolSaveFile: 'Save & Export',
  toolExit: 'Quit',

  toolUndo: 'Undo',
  toolRedo: 'Redo',

  warningMax8Lights: 'You can add at most 8 lights.',
  warningAreYouSure: 'Are you sure?',
  warningCameraPermissions: 'Allow Camera access in Settings 👉 Reflight Studio 👉 Camera',

  differentialPreview: 'Before & After',
  closePreview: 'Close Preview',

  layerBokeh: 'Bokeh',
  layerSharpness: 'Sharpness',
  layerBrightness: 'Brightness',
  layerColor: 'Color',
  layerContrast: 'Contrast',
  layerDistance: 'Distance',
  layerFalloff: 'Falloff',
  layerVignette: 'Vignette',

  lightStrength: 'Strength',
  lightSpread: 'Spread',
  lightColor: 'Color',
  lightSaturation: 'Saturation',
  lightBrightness: 'Brightness',
  lightOffset: 'Offset',

  sliderNear: 'near',
  sliderFar: 'far',
  sliderGentle: 'gentle',
  sliderQuick: 'sharp',
  sliderOn: 'on',
  sliderOff: 'off',
  sliderNarrow: 'narrow',
  sliderWide: 'wide',

  exportNativeStarted: 'Exporting...',
  exportNativeSuccess: 'Picture saved',
  exportNativeErrorExport: `Can't save the Picture`,
  exportNativeErrorAlbum: `Can't access Photos`,

  download1: 'Hold finger on picture',
  download2: 'and press',
  download3: 'Save to Photos',
};

const translationThai = {
  openPicture: 'เลือกภาพถ่าย',
  preparingPicture: 'แป๊บหนึ่งนะค่ะ',
  cancelOption: 'ละทิ้ง?',
  cancellingPicture: 'กำลังละทิ้งค่ะ',

  toolRevert: 'รีเซ็ต',
  toolRotate: 'หมุน',
  toolCrop: 'ครอป',
  toolAddLight: 'หลอดไฟ',
  toolBackground: 'พื้นหลัง',
  toolForeground: 'พื้นหน้า',
  toolPreview: 'ดูผลลัพธ์',

  toolSaveFile: 'บันทึกไฟล์',
  toolExit: 'ปิดไฟล์',

  toolUndo: 'undo',
  toolRedo: 'redo',

  warningMax8Lights: 'สูงสุด8หลอดไฟ หนุ่มหล่อ',
  warningAreYouSure: 'คุณแน่ใจมั้ยนะ?',
  warningCameraPermissions: 'เปิดใช้งานกล้องใน Settings 👉 Reflight Studio 👉 Camera',

  differentialPreview: 'ก่อนหลัง',
  closePreview: 'ปิด',

  layerBokeh: 'เลนส์เบลอ',
  layerSharpness: 'ความคมชัด',
  layerBrightness: 'ความสว่าง',
  layerColor: 'ความอิ่มตัว',
  layerContrast: 'ความคมชัด',
  layerDistance: 'พิสัย',
  layerFalloff: 'การหายไป',
  layerVignette: 'ขอบความมืด',

  lightStrength: 'ความเข้ม',
  lightSpread: 'ขอบเขต',
  lightColor: 'สี',
  lightSaturation: 'ความอิ่มตัว',
  lightBrightness: 'ความสว่าง',
  lightOffset: 'พิสัย',

  sliderNear: 'ใกล้',
  sliderFar: 'ไกล',
  sliderGentle: 'ละมุน',
  sliderQuick: 'สาก',
  sliderOn: 'เปิด',
  sliderOff: 'ปิด',
  sliderNarrow: 'แคบ',
  sliderWide: 'กว้าง',

  exportNativeStarted: 'กำลังบันทึก...',
  exportNativeSuccess: 'บันทึกเรียบร้อย',
  exportNativeErrorExport: 'บันทึกไม่ได้',
  exportNativeErrorAlbum: 'เข้าถึงอัลบั้มไม่ได้',

  download1: 'กดนิ้วบนรูปภาพ',
  download2: 'จากนั้นกด',
  download3: 'บันทึกลงในรูปภาพ'
};

const userLanguagePreference = navigator.language ||
  // @ts-ignore
  navigator.userLanguage ||
  navigator.languages[0];
  
export const userLanguageIsThai = userLanguagePreference.includes('th');
export const translation = userLanguageIsThai ? translationThai : translationEnglish;
