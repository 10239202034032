// import { storeValue } from "./storage";

export const setupMocks = async () => {
  // await storeValue({
  //   "file": {
  //       "path": "T_2_5_Wood_basecolor.png",
  //       "base64": baseMock,
  //       "depthBase64": depthMock,
  //       "width": 1024,
  //       "height": 1024
  //   },
  //   "tool": "move",
  //   "workingArea": {
  //       "width": 1,
  //       "height": 1
  //   },
  //   "workingAreaRotation": 0,
  //   "cropBox": {
  //       "x1": 0,
  //       "y1": 0,
  //       "x2": 1,
  //       "y2": 1
  //   },
  //   "viewportCenter": {
  //       "x": 0,
  //       "y": 0
  //   },
  //   "historyBack": [],
  //   "historyForth": [],
  //   "notification": null,
  //   "leftBar": "modifiers",
  //   "lights": [],
  //   "background": {
  //       "sharpness": 1,
  //       "brightness": 0.5,
  //       "saturation": 0.5,
  //       "contrast": 0.5,
  //       "bokeh": 0,
  //       "vignette": 0,
  //       "offset": 0.5,
  //       "offsetStride": 0.5
  //   },
  //   "foreground": {
  //       "sharpness": 1,
  //       "brightness": 0.5,
  //       "saturation": 0.5,
  //       "contrast": 0.5,
  //       "bokeh": 0,
  //       "vignette": 0
  //   }
  // }, 'T_2_5_Wood_basecolor.png');
};
