import { HTMLAttributes } from "react";
import styled from "styled-components";

export const Icon = ({
  name,
  size = 16,
  padding = 6,
  rotation = 0,
  color,
  highlight = false,
  ...rest
}: {
  name: string;
  size: number;
  padding?: number;
  rotation?: number;
  color?: string;
  highlight?: boolean;
} & HTMLAttributes<HTMLDivElement>): JSX.Element => {
  return (
    <Container
      $size={size}
      {...rest}
    >
      <IconMask
        $name={name}
        $size={size}
        $padding={padding}
        $rotation={rotation}
        $color={color}
      />
      {highlight && (
        <Highlight
          $padding={padding}
        />
      )}
    </Container>
  );
};

const Highlight = styled.div<{
  $padding: number;
}>`
  @keyframes HighlightPopIn {
    0% {
      transform: translate(-50%, -50%) scale(0.0);
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }

    60% {
      transform: translate(-50%, -50%) scale(1.2);
      filter: blur(4px);
      -webkit-filter: blur(4px);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
      filter: blur(0px);
      -webkit-filter: blur(0px);
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + ${({ $padding }) => $padding}px);
  height: calc(100% + ${({ $padding }) => $padding}px);
  transform: translate(-50%, -50%);
  background-color: #ffffff11;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 1;
  animation: HighlightPopIn 0.1s ease-in-out alternate 1;
`;

const IconMask = styled.div<{
  $name: string;
  $size: number;
  $padding: number;
  $rotation: number;
  $color?: string;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(${({ $rotation }) => $rotation}rad);
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  mask: url(${({ $name }) => `./icons/${$name}.svg`});
  mask-size: calc(100% - ${({ $padding }) => $padding * 2}px);
  mask-position: center;
  mask-repeat: no-repeat;
  background: ${({ $color }) => $color || "#cccccc"};
  z-index: 2;
`;

const Container = styled.div<{
  $size: number;
}>`
  position: relative;
  display: inline-block;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
`;
